

// Your Slick Slider initialization code here
$('.carousel.owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    autoplay:true,
    autoplaySpeed: 1200,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
});
$('.trust-slider').owlCarousel({
  loop: true,
  margin: 40,
  draggable: true,
  nav: false,
  dots: true,
  autoplay: true,
  autoplayTimeout: 2000,
  responsive:{
      0:{
        items: 1,
        dots: false,
        nav: true,
      },
      600:{
        items: 2
      },
      800:{
        items: 3
      },
      1100:{
        items: 5
      },
      1250:{
        items: 6,
        dots: true,
      }
  }
});
  // Function to set equal heights
  function setEqualHeight(selector) {
    var elements = document.querySelectorAll(selector);
    var maxHeight = 0;
    elements.forEach(function(el) {
      maxHeight = Math.max(maxHeight, el.offsetHeight);
    });
    elements.forEach(function(el) {
      el.style.height = maxHeight + 'px';
    });
  }

  // Call the function on page load and window resize
  window.onload = function() {
    setEqualHeight('.category-grid .grid-text');
  };

  window.addEventListener('resize', function() {
    setEqualHeight('.category-grid .grid-text');
  });

  $(document).ready(function(){


    $('.navbar-nav li.menu-item-has-children').click(function() {
      // Close previously opened sub-items
      $('li.menu-item-has-children.active').not(this).removeClass('active').find('.sub-menu').slideUp();

      // Toggle the clicked item
      $(this).toggleClass('active');
      $(this).find('.sub-menu').slideToggle();
    });

    $(".mob-men").click(function() {
      $(".mobilemenu").toggleClass("show");
      
    });
  });